import counter from "../assets/counter1600x600.webp";
import { useState, useEffect } from "react";

const Timer = () => {
  const [timer, setTimer] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
    count: 0,
    activ: true,
  });

  const countdown = () => {
    const year = new Date().getFullYear();
    const count = year - 2021;
    const countDownDate = new Date(`Aug 4, ${year} 00:00:00`).getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;

    if (distance < 0) {
      setTimer({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
        count,
        activ: false,
      });
      return;
    }

    const formatNumber = (number) =>
      number > 9 ? number.toString() : "0" + number;

    const days = formatNumber(Math.floor(distance / (1000 * 60 * 60 * 24)));
    const hours = formatNumber(
      Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    );
    const minutes = formatNumber(
      Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    );
    const seconds = formatNumber(Math.floor((distance % (1000 * 60)) / 1000));

    setTimer({ days, hours, minutes, seconds, count, activ: true });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      countdown();
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <section
      className="section counter-area center-text"
      style={{
        backgroundImage: `url(${counter})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="heading">
              <h2 className="title">Bald ist es wieder soweit!</h2>
              <h3 className="title">{timer.count}ter Hochzeitstag</h3>
              <span className="heading-bottom">
                <i className="color-white icon icon-star"></i>
              </span>
            </div>
          </div>
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <div className="remaining-time">
              <div id="clock">
                <div className="time-sec">
                  <span className="title">{timer.activ ? timer.days : 0}</span>{" "}
                  Tage
                </div>

                <div className="time-sec">
                  <span className="title">{timer.activ ? timer.hours : 0}</span>{" "}
                  Stunden
                </div>

                <div className="time-sec">
                  <span className="title">
                    {timer.activ ? timer.minutes : 0}
                  </span>{" "}
                  Minuten
                </div>

                <div className="time-sec">
                  <span className="title">
                    {timer.activ ? timer.seconds : 0}
                  </span>{" "}
                  Sekunden{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timer;
